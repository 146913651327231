<template>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6L6.5 12L1 6" stroke="currentColor" stroke-width="1.5" />
    <line
      y1="-0.75"
      x2="11"
      y2="-0.75"
      transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 5.75 11)"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
